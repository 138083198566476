import { checkBankScore } from '../helpers/CheckFunctions';
import i18n from '../../i18n';
export function getErrorMessage() {
	const { prop_sx_AgreementNumber, prop_sx_scoreSum, associations, file } = this.state;
	if (!(prop_sx_AgreementNumber && prop_sx_scoreSum)) {
		return i18n.t("notAllRequiredFildsAreSpecifiedMessage");
	}
	else if (!(associations?.mainContent || file)) {
		return i18n.t("3");
	}
}