import React, { Component } from 'react';
import { NavLink } from 'reactstrap';
import { leftMenuTypes } from '../../constants';
import { Link } from 'react-router-dom';
import { Svg } from '../Svg';
import { globalEventDispatcher, dispatcherActionTypes } from '../helpers/GlobalEventDispatcher';
export class MenuItem extends Component {
    constructor(props) {
        super(props);
        this.state = {
            collapsed: true
        };
        this.toggleCollapsing = this.toggleCollapsing.bind(this);
        this.globalEventListerner = this.globalEventListerner.bind(this);
        globalEventDispatcher.subscribe(this.globalEventListerner);
    }
    toggleCollapsing(e, value) {
        const { menuItems, handlerSetExpandedLink, link } = this.props;
        const { collapsed } = this.state;
        if (e) e.preventDefault();
        let collapsedValue = value !== undefined ? value : !collapsed;
        if (collapsedValue != collapsed && menuItems?.length > 0) {
            this.setState({
                collapsed: collapsedValue
            });
            if (!collapsedValue && handlerSetExpandedLink && link?.url)
                handlerSetExpandedLink(link.url);
        }
    }
    componentWillUpdate() {
        const { link, menuItems, handlerGetExpandedLink } = this.props;
        const { collapsed } = this.state;
        const expandedLink = handlerGetExpandedLink ? handlerGetExpandedLink() : null;
        if (expandedLink && expandedLink !== link?.url && !expandedLink.startsWith(link?.url + '/') && !collapsed) {
            this.setState({
                collapsed: true
            });
        }
        else if (expandedLink && (expandedLink === link?.url || expandedLink.startsWith(link?.url + '/')) && collapsed) {
            this.setState({
                collapsed: false
            });
        }
    }
    globalEventListerner(actionType) {
        if (actionType === dispatcherActionTypes.onTabChanged) {
            const { collapsed } = this.state;
            const { link, handlerSetExpandedLink } = this.props;
            const curUrl = this.getCurrentUrl();
            if (link && (curUrl === link.url || curUrl === link?.url + '/') && collapsed) {
                this.setState({
                    collapsed: false
                });
                if (handlerSetExpandedLink)
                    handlerSetExpandedLink(link.url);
            }
        }
    }
    componentDidMount() {
        this.componentWillUpdate();
    }
    getCurrentUrl() {
        return window.location.pathname;
    }
    isSelected(url) {
        if (url) {
            const curUrl = this.getCurrentUrl();
            let isSelected = curUrl === url || curUrl === url + '/';
            isSelected = isSelected || (curUrl.startsWith(url + '/') && url !== "/");
            return isSelected;
        }
        else
            return false;
    }
    render() {
        const { link, image, counter, menuItems, isRoot, type, handlerGetExpandedLink, handlerSetExpandedLink } = this.props;
        let items = menuItems ? menuItems.filter(item => !item?.hiddenForMenu).map((item, i) =>
            <MenuItem
                key={item.name ? item.name : i}
                link={item.link}
                type={item.type}
                collapsed={!item.expanded}
                name={item.name}
                image={item.image}
                counter={item.counter}
                menuItems={item.menuItems}
                handlerGetExpandedLink={handlerGetExpandedLink}
                handlerSetExpandedLink={handlerSetExpandedLink}
            />) :
            [];
        let isSelected = this.isSelected(link?.url);
        switch (type) {
            case leftMenuTypes.divider: return (<li className={"menu-line"}></li>);
            case leftMenuTypes.link:
                return (
                    <li className={(isRoot ? "left-menu-item root-item " : "left-menu-item ") + (isSelected ? "selected " : "")}>
                        <div className="menu-item-link">
                            <NavLink className={isSelected ? "selected" : ""} tag={Link} to={link?.url ? link?.url : "/"} onClick={() => handlerSetExpandedLink(link?.url ? link?.url : "/")}>
                                {link?.text}
                                {counter && !counter.hidden ? <span className={"left-menu-counter" + (isSelected ? " selected" : "")}>{counter?.count}</span> : ""}
                                {items.length > 0 ?
                                    <div className="collapse-menu-btn" onClick={this.toggleCollapsing}>
                                        <span className={(this.state.collapsed ? "collapsed-menu-icone" : "expanded-menu-icone") + (isSelected ? " selected" : "")} />
                                    </div>
                                    : ""
                                }
                            </NavLink>
                        </div>
                        {image?.path && <Svg className="main-menu-icone" src={image?.path} />}
                        {
                            !(this.state.collapsed) ?
                                <ul className={items.length > 0 ? "with-padding" : ""}>
                                    {items.map(item =>
                                        item
                                    )}
                                </ul> : ""
                        }
                    </li>
                );
            default: return null;
        }
    }
}