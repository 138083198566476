import React from 'react';
import { History, actionTypes, urls, searchTableTypes, alfrescoRoles } from '../../constants';
import i18next from "../../i18n";
import { EditUserForm } from '../forms/EditUserForm';
import { UserInvitationForm } from '../forms/UserInvitationForm';
import { getArrayIds, encodeHTML } from '../helpers/HelperFunctions';
import { hideModalDialog, showModalDialog } from '../ModalDialog';
import { Table } from '../table/Table';
import { TableFilterBlock } from '../table/TableFilterBlock';
import * as ListOfUserCompanies from './ListOfUserCompanies';
import { ListOfAlfresco } from './ListOfAlfresco';
import { loadListData } from './ListHelpers';
export async function loadData(page, pageSize, sortBy = '', isDesk = false, filterValues = []) {
    await loadListData.bind(this)(`Table/Get?searchTableTypes=${searchTableTypes.Users}`, page, pageSize, sortBy, isDesk, filterValues);
}
export function inviteUserHandler() {
    showModalDialog("loadingDialog", i18next.t("inviteUser"), "", <UserInvitationForm formType={this.props.type} companies={this.context.companies} onCloseHandler={() => { hideModalDialog("loadingDialog"); }} />, "feedPopup middle-form");
}
export async function userAction(ids, path) {
    const response = await fetch(`/siteSettings/${path}`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(ids.join('|'))
    });
    if (!response.ok) {
        if (response.status === 403) History.push(urls.accessDeniedPage);
        else require('../ModalDialog').unknownErrorMessageDialog();
        return;
    }
    hideModalDialog("loadingDialog");
    const { page, pageSize, sortBy, isDesk, filterValues } = this.state;
    this.loadData(page, pageSize, sortBy, isDesk, filterValues);
}
export async function actionHandlerCallback(actionType, ids) {
    const { page, pageSize, sortBy, isDesk, filterValues } = this.state;
    let closeDialog = function () {
        hideModalDialog("loadingDialog");
    }
    if (ids.length > 0) {
        switch (actionType) {
            case actionTypes.edit:
                showModalDialog("loadingDialog", i18next.t("editUser"), "", <EditUserForm userId={ids[0]} onSubmitedHandler={() => this.loadData(page, pageSize, sortBy, isDesk, filterValues)} onCloseHandler={() => { hideModalDialog("loadingDialog"); }} />, "feedPopup");
                break;
            case actionTypes.unblock:
                showModalDialog("loadingDialog", i18next.t("unblockUsersMessageTitle"), "", <div className="info-message">{i18next.t("unblockUsersMessage")}</div>, "feedPopup", true, [{ title: i18next.t("yes"), clickHandler: () => this.userAction(ids, "unblockUsers"), class: "btn-primary" }, { title: i18next.t("no"), clickHandler: closeDialog, class: "btn-secondary" }]);
                break;
            case actionTypes.block:
                showModalDialog("loadingDialog", i18next.t("blockUsersMessageTitle"), "", <div className="info-message">{i18next.t("blockUsersMessage")}</div>, "feedPopup", true, [{ title: i18next.t("yes"), clickHandler: () => this.userAction(ids, "blockUsers"), class: "btn-primary" }, { title: i18next.t("no"), clickHandler: closeDialog, class: "btn-secondary" }]);
                break;
            case actionTypes.join:
                showModalDialog("loadingDialog", i18next.t("7"), "", <ListOfAlfresco userId={ids[0]} parentComponent={ListOfUserCompanies} />, "feedPopup agreement middle-size");
                break;
            case actionTypes.reset:
                let handlerResetPassword = () => {
                    closeDialog();
                    for (let i = 0; i < ids.length; ++i) {
                        fetch(`/account/ResetPasswordById`, { method: 'Post', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify({ id: ids[i] }) })
                            .then(res => {
                                if (res.ok) {
                                    require('../ModalDialog').messageDialog(i18next.t("done"), i18next.t('userPasswordResetMessage'));
                                }
                                else {
                                    require('../ModalDialog').unknownErrorMessageDialog();
                                }
                            });
                    }
                }
                showModalDialog("loadingDialog", i18next.t("message"), "", <div className="info-message">{i18next.t("resetPasswordMessage")}</div>, "feedPopup", true, [{ title: i18next.t("yes"), clickHandler: handlerResetPassword, class: "btn-primary" }, { title: i18next.t("no"), clickHandler: closeDialog, class: "btn-secondary" }]);
                break;
            default:
                await this.actionHandlerCallbackDefault(actionType, ids);
                break;
        }
    }
}
export function renderContent() {
    const { actionsForAll, companyName } = this.props;
    const { content, page, pageSize, pageCount, sortBy, isDesk, filterValues } = this.state;
    let additionalBtn = this?.inviteUserHandler && [alfrescoRoles.MODERATOR, alfrescoRoles.PORTAL_ADMINISTRATOR].includes(this.context?.role) ? { title: i18next.t("inviteUser"), onClickHandler: this.inviteUserHandler } : null;
    let contentRows = content?.rows ?? [];
    if (companyName && contentRows.length > 0) {
        contentRows.forEach(row => {
            row.columns.CompanyName = encodeHTML(companyName);
        });
    }

    return (
        <div>
            <TableFilterBlock filterValues={filterValues} additionalBtn={additionalBtn} exportFileName="users" onSearch={this.onSearch} onSearchClear={this.onSearchClear} onColumnsUpdateHandler={this.onColumnsUpdateHandler} columnsSettings={{ columns: (content && content.allColumns) ? content.allColumns : [], selected: (content && content.columns) ? getArrayIds(content.columns) : [] }} getItems={this.getItemsForExport} handleUpdate={this.handleReloadData} />
            {content && content.columns &&
                <Table onColumnFilter={this.onColumnFilter} allowDoubleClickAction={true} allCount={content.allCount} handleSortChange={this.handleSortChange} actionHandlerCallback={this.actionHandlerCallback} actionsForAll={actionsForAll ? actionsForAll : content.actionsForAll} columns={content.columns} sortBy={sortBy} isDesk={isDesk} items={contentRows} showPagination={true} page={page} pageSize={pageSize} pageCount={pageCount} handlePageChange={this.handlePageChange} handleChangePageSize={this.handleChangePageSize} />
            }
        </div>
    );
}