import React, { Component } from 'react';
import i18next from "../../i18n";
import { UserContext } from '../../constants';
import { Content } from '../Content';
import * as HelperFunctions from '../helpers/HelperFunctions';
export class ResponseStatusePage extends Component {
    static contextType = UserContext;
    constructor(props) {
        super(props);
        this.state = { currentCompanyName: '' };
    }
    componentDidMount() {
        if (!this.context)
            HelperFunctions.getCurrentCompanyInfo().then((info) => { this.setState({ currentCompanyName: info?.name }); });
    }
    render() {
        const { code } = this.props;
        if (this.context && code != 500) {
            return (<Content>
                <div className="content">
                    <div className="_404">
                        <div className="_404-code">{code}</div>
                        <div className="_404-title">{i18next.t([`statusCode.${code}.Title`])}</div>
                        <div className="_404-sub-title">{i18next.t(`statusCode.${code}.SubTitle`)}</div>
                    </div>
                </div>
            </Content>);
        }
        else {
            let root = document.getElementById('root');
            let rootBlock = root?.querySelector('.root-block');
            if (rootBlock) {
                rootBlock.classList.add("with-bg");
            }
            return (<div className="form-page">
                <div className="form-block large-form">
                    <div className="form-header">
                        <div className="form-header-subtitle-right form-subtitle">{this.state.currentCompanyName}</div>
                    </div>
                    <div className="form-content">
                        <div className="form-group">
                            <div className="info-message-title">{code}</div>
                            <div className="info-message">{i18next.t(`statusCode.${code}.Title`)} {i18next.t(`statusCode.${code}.SubTitle`)}</div>
                        </div>
                        <div className="form-group form-group__last"><button className="btn btn-primary" onClick={HelperFunctions.logout}>{i18next.t("ok")}</button></div>
                    </div>
                </div>
            </div>);
        }
    }
}