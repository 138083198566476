import React from 'react';
import InputMask from 'react-input-mask';
import { companyTypes, formFieldTypes, formStringFieldPatterns } from '../../constants';
import i18next from "../../i18n";
import { DateTimeInput } from '../forms/DateTimeInput';
import * as CheckFunctions from '../helpers/CheckFunctions';
import { getLocalDateString } from '../helpers/HelperFunctions';
export function InpuContainer(props) {
    const check = props.Check ? checkField(props) : false;
    let { Name, Label } = props;
    let label = <label htmlFor={Name} className={props.Required ? "required" : ""}>{Label?.Label}</label>
    return (props.Type === formFieldTypes.empty ? "" : <>
        {label}
        {props.children}
        {check ? < div className="help-block">{check}</div> : ""}
    </>);
}
export function buildFormField(field, changeInputValue) {
    let changeInputValueHandle = (e) => {
        e.target.value = e.target.value.replaceAll(' ', '');
        const { value } = e.target;
        if ((/^[0-9]{1,}[,]?([0-9]{1,2})?$/).test(String(value)) || !value) changeInputValue(e);
    }
    let onBlurNumberHandle = (e) => {
        const { value } = e.target;
        if (value.endsWith(',')) e.target.value = value.replace(',', '');
        changeInputValue(e);
    }
    if (field) {
        let { Name, Value, Disabled } = field;
        if (Value && field.Type === formFieldTypes.datetime) {
            let date = getLocalDateString(new Date(Value)).split(".");
            Value = `${date[2]}-${date[1]}-${date[0]}`;
        }
        switch (field.Type) {
            case formFieldTypes.string:
            case formFieldTypes.int:
                switch (field.Patern) {
                    case formStringFieldPatterns.number:
                        return (<input className="form-control" value={Value.replace(/\B(?=(\d{3})+(?!\d))/g, " ")} name={Name} onChange={changeInputValueHandle} onBlur={(e) => onBlurNumberHandle(e)} disabled={Disabled ? "disabled" : ""} />);
                    case formStringFieldPatterns.phone:
                        return (<InputMask type="tel" className="form-control" value={Value} name={Name} mask='+380999999999' maskChar={null} onChange={changeInputValue} disabled={Disabled ? "disabled" : ""} />);
                    case formStringFieldPatterns.iBan:
                        return (<InputMask type="tel" className="form-control" value={Value} name={Name} mask='UA999999999999999999999999999' maskChar={null} onChange={changeInputValue} disabled={Disabled ? "disabled" : ""} />);
                    default:
                        return (<>
                            {field.IsMultiline ?
                                <textarea name={Name} className="form-control" value={Value} rows="4" onChange={changeInputValue} disabled={Disabled ? "disabled" : ""} /> :
                                <input className="form-control" pattern={field.Patern} type={(field.Type === formFieldTypes.int ? "number" : "")} value={Value} name={Name} onChange={changeInputValue} disabled={Disabled ? "disabled" : ""} />
                            }
                        </>);
                }
            case formFieldTypes.datetime:
                return (<DateTimeInput className="form-control" disabled={Disabled} onlyDate={true} value={Value} required={field.Required} format="date" name={field.Name} placeholder={field.Placeholder} onChange={changeInputValue} />);
            case formFieldTypes.select:
                return (<select name={Name} className="form-control" value={Value} onChange={changeInputValue} disabled={Disabled ? "disabled" : ""}>
                    {field?.Values?.Data?.map(item =>
                        <option key={item.Value} value={item.Value} style={{ display: item.Hidden ? "none" : "" }}> {item.Label}</option>
                    )}
                </select>);
            case formFieldTypes.bool:
                return (<div className="double-checkbox-form">
                    <div>
                        <input id={Name} name={Name} checked={Value} className="form-control" onChange={changeInputValue} type="checkbox" disabled={Disabled ? "disabled" : ""} />
                        <label htmlFor={Name}></label>
                    </div>
                </div>);
            case formFieldTypes.file: return (<>
                <label className="upload-file-label upload-file-btn" onClick={() => document.getElementById(Name).click()}>{i18next.t("addFile")}</label>
                <input className="file-select" name="file" id={Name} onChange={changeInputValue} type="file" style={{ display: "none" }} />
            </>);
        }
    }
}
export function checkField(field) {
    let requiredMessage = i18next.t("requiredField");
    let wrongFormatMessage = i18next.t("badFieldFormat");
    let wrongIbanMessage = i18next.t("wrongIbanMessage");
    if (field) {
        let fieldValue = field.Value;
        if (field.Required && !fieldValue) return requiredMessage;
        if (field?.Patern && fieldValue) {
            switch (field.Patern) {
                case formStringFieldPatterns.codeEDRPO:
                    return !CheckFunctions.checkCompanyRegisterCode(fieldValue) ? wrongFormatMessage : "";
                case formStringFieldPatterns.codeIPN:
                case formStringFieldPatterns.legalPersonIPN:
                    return !CheckFunctions.checkPersoneRegisterCode(fieldValue, field.Patern === formStringFieldPatterns.legalPersonIPN ? companyTypes.Entity : null) ? wrongFormatMessage : null;
                case formStringFieldPatterns.email:
                    return !CheckFunctions.checkEmail(fieldValue) ? wrongFormatMessage : "";
                case formStringFieldPatterns.mFO:
                    return !CheckFunctions.checkMFO(fieldValue) ? wrongFormatMessage : "";
                case formStringFieldPatterns.phone:
                    return !CheckFunctions.checkPhone(fieldValue) ? wrongFormatMessage : "";
                case formStringFieldPatterns.iBan:
                    return !CheckFunctions.checkBankScore(fieldValue) ? wrongIbanMessage : "";
            }
        }
    }
}
export function getFormSection(form, sectionName) {
    const formSections = form?.Sections;
    const currSection = formSections?.find((item) => item.Name === sectionName);
    let firstIndex = form?.Fields.findIndex((item) => currSection?.FirstFieldName && item.Name === currSection?.FirstFieldName);
    let lastFieldIndex = form?.Fields.findIndex((item) => currSection?.LastFieldName && item.Name === currSection?.LastFieldName);
    let sectionItems = (firstIndex !== -1 && lastFieldIndex !== -1) ? form?.Fields.slice(firstIndex, lastFieldIndex + 1) : [];
    return { label: currSection?.Label?.Label, sectionItems: Array.from(sectionItems) }
}
export function getFormSectionSettings(form, sectionName) {
    return form.Sections.find((item) => item.Name === sectionName);
}
export function padWithZeroes(number, length) {
    var my_string = '' + number;
    while (my_string.length < length) my_string = '0' + my_string;
    return my_string;
}