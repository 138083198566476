import i18n from '../../i18n';
export function buildFormItem(item) {
	const { prop_sx_dfState } = this.state;
	if (item.DisableWhile) {
		item.Disabled = item.DisableWhile.indexOf(prop_sx_dfState) !== -1;
	}
	return item;
}
export function getErrorMessage() {
	const { prop_sx_AgreementNumber, prop_sx_InvoiceSum, associations, file } = this.state;
	if (!(prop_sx_AgreementNumber && prop_sx_InvoiceSum)) {
		return i18n.t("notAllRequiredFildsAreSpecifiedMessage");
	}
	else if (!(associations?.mainContent || file)) {
		return i18n.t("3");
	}
}