import React from 'react';
import { NavLink } from 'reactstrap';
import { Link } from 'react-router-dom';
import { Svg } from '../Svg';
export function NavMenuItem(props) {
    const { title, name, url, classes, menuItems, src, target } = props;
    let items = [];
    if (menuItems) {
        for (let i = 0; i < menuItems.length; i++) {
            let item = menuItems[i];
            if (!item.hiddenForMenu)
                items.push(<NavMenuItem key={item.name ? item.name : i} isRoot={false} menuItems={item.menuItems} target={item.target} classes={item.class + " dropdown-item"} type={item.type} title={item.link.text} name={item.name} src={item.icone} count={item.count} url={item.link.url} />)
        }
    }
    if (items.length > 0)
        return (<div className="nav-link">
            <Svg src={src} className="nav-menu-icone" />
            <div className="dropdown">
                <div className="dropdown-btn" id={"nav-btn" + name} data-toggle="dropdown">
                    <span>{title}</span>
                    <svg className="expanded-menu" width="12" height="7" viewBox="0 0 12 7" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fillRule="evenodd" clipRule="evenodd" d="M5.29289 6.70712L0.292892 1.70712L1.70711 0.292908L6 4.5858L10.2929 0.292908L11.7071 1.70712L6.70711 6.70712C6.31658 7.09765 5.68342 7.09765 5.29289 6.70712Z" fill="black" />
                    </svg>
                </div>
                <div className="dropdown-menu" aria-labelledby={"nav-btn" + name}>
                    {items.map(item =>
                        item
                    )}
                </div>
            </div>
        </div>)
    else
        return (
            <NavLink tag={Link} className={classes ? classes : ""} to='' onClick={() => window.location.replace(url)} target={target} >
                {src && <Svg src={src} className="nav-menu-icone" />}
                <span>{title}</span>
            </NavLink>
        );
}