import React, { Component } from 'react';
import * as DocumentHelpers from './DocumentHelpers';
import { formModes } from '../../constants';
import * as ModalDialogsTemplates from '../ModalDialog';
import * as constants from '../../constants';
import * as ModalDialog from '../ModalDialog';
import * as Document from './Document';
import i18next from "../../i18n";
export async function dynamicFormHandler(path, type, ids = []) {
    switch (type) {
        case constants.actionTypes.edit:
            return ModalDialog.showModalDialog('modalDialog', i18next.t("edit"), "", <DynamicForm path={path} id={ids[0]} />, "feedPopup  middle-form")
        case constants.actionTypes.preview:
            return ModalDialog.showModalDialog('modalDialog', i18next.t("view"), "", <DynamicForm path={path} id={ids[0]} />, "feedPopup  middle-form")
        case constants.actionTypes.remove:
            let response = await fetch('/Document/' + (constants.alfrescoTypes.additionalFiles.endsWith(path) === true ? `Delete?id=${ids[0]}` : `RemoveAssoc?id=${window.location.pathname.split('/').pop()}&name=${path}&assocId=${ids[0]}`), { method: 'POST' });
            if (response.ok) window.location.reload()
            else ModalDialogsTemplates.unknownErrorMessageDialog()
            break;
    }
}
export class DynamicForm extends Component {
    constructor(props) {
        super(props);
        const { id, path } = this.props;
        this.state = {
            form: null,
            submitted: false,
            type: `sx:${path}`,
            formMode: id ? formModes.edit : formModes.create
        }
        try { this.buildFormItem = require(`./${path}`).buildFormItem.bind(this) } catch { this.buildFormItem = (item) => item }
    }
    componentDidMount() {
        const { id } = this.props;
        Document.getForm.bind(this)(id);
    }
    render() {
        const { form } = this.state;
        return (form ?
            <>
                <div className="modal-body">
                    <form name="form" className="popupSimple">
                        <div className="row">
                            {DocumentHelpers.buildFormSection.bind(this)('information')}
                        </div>
                    </form>
                </div>
                <div className="modal-footer">
                    <div className="form-group form-group__last">
                        <button className="btn btn-primary" onClick={this.handleSubmit.bind(this)}>{i18next.t("save")}</button>
                        <button type="button" className="btn btn-secondary" onClick={() => ModalDialog.hideModalDialog('modalDialog')}>{i18next.t("cancel")}</button>
                        <div className="required-label">
                            <label className="required" />{i18next.t("requiredFields")}
                        </div>
                    </div>
                </div>
            </> : '');
    }
    async handleSubmit() {
        const { path } = this.props;
        const { formMode, prop_sys_node_uuid } = this.state;
        const props = DocumentHelpers.getFormFields.bind(this)();
        await fetch(`Document/${formMode}${path}/${prop_sys_node_uuid ? prop_sys_node_uuid : window.location.pathname.split('/').pop()}`, { method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(props) })
            .then(async (response) => {
                if (response.ok) window.location.reload();
                else ModalDialogsTemplates.unknownErrorMessageDialog();
            });
    }
}