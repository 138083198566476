import React, { Component } from 'react';
import { Navbar, NavItem, NavLink } from 'reactstrap';
import { UserContext, resources } from '../../constants';
import { Link } from 'react-router-dom';
import { NavMenuItem } from './NavMenuItem';
import { logout } from '../helpers/HelperFunctions';
import i18next from "../../i18n";
import { Svg } from '../Svg';
export class NavMenu extends Component {
    static contextType = UserContext;
    constructor(props) {
        super(props);
        this.state = {
            collapsed: true,
            menuOpen: false,
            isOpenLeftMenu: false,
            menuItems: props?.NavMenu?.menuItems ?? []
        };
        this.handleOpenLeftMenu = this.handleOpenLeftMenu.bind(this);
        this.handleChangeCompany = this.handleChangeCompany.bind(this);
    }
    componentDidUpdate() {
        if (this.props.isOpenLeftMenu !== this.state.isOpenLeftMenu) {
            this.setState({
                isOpenLeftMenu: this.props.isOpenLeftMenu
            });
        }
    }
    handleOpenLeftMenu() {
        let isOpen = !this.state.isOpenLeftMenu;
        this.setState({
            isOpenLeftMenu: isOpen
        });
        if (this.props.handleOpenLeftMenu)
            this.props.handleOpenLeftMenu(isOpen);
    }
    hideOverMob() {
        this.setState({
            menuOpen: false
        });
    }
    render() {
        const { menuItems, isOpenLeftMenu } = this.state;
        const menuItem = this.context.mainMenuItem;
        return <>
            <header>
                <Navbar className="navbar-expand-sm navbar-toggleable-sm ng-white box-shadow" light>
                    <button type="button" className={"BtnMobileLeftBar" + (isOpenLeftMenu ? " active" : "")} onClick={this.handleOpenLeftMenu} >
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M21 6L8 6L8 8L21 8L21 6ZM21 11L11 11L11 13L21 13L21 11ZM21 18L21 16L8 16L8 18L21 18ZM3 8.41L6.58 12L3 15.59L4.41 17L9.41 12L4.41 7L3 8.41Z" fill="black" />
                        </svg>
                    </button>
                    <div className="main-logo">
                        <NavLink tag={Link} to="/">
                            <img src="/img/logo.svg" width="230" alt="Logo" />
                        </NavLink>
                    </div>
                    <div className={this.state.menuOpen ? "menuMobile open" : "menuMobile"} open={this.state.menuOpen}>
                        <ul className="navbar-nav flex-grow">
                            {menuItems?.map((item, index) =>
                                <NavItem key={index++}>
                                    <NavMenuItem title={item.link.text} target={item.target} menuItems={item.menuItems} classes={item.classes} src={item.image.path} url={item.link.url} isRoot={true} />
                                </NavItem>
                            )}
                        </ul>
                        <div className="account-info-block">
                            <div className="dropdown">
                                {this.context.companies?.length > 0 &&
                                    <>
                                        <div className="dropdown-btn" id="account-info-nav-btn" data-bs-toggle="dropdown" aria-expanded="false">
                                            <div className="account-info">
                                                <div>
                                                    <div className="account-info-name">{this.context.userName}</div>
                                                    <div className="account-info-key">{this.context.companies[0].companyShortName}</div>
                                                </div>
                                                <svg className="expanded-menu" width="12" height="7" viewBox="0 0 12 7" fill="none" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" clipRule="evenodd" d="M5.29289 6.70712L0.292892 1.70712L1.70711 0.292908L6 4.5858L10.2929 0.292908L11.7071 1.70712L6.70711 6.70712C6.31658 7.09765 5.68342 7.09765 5.29289 6.70712Z" fill="black"></path></svg>
                                            </div>
                                        </div>
                                        <div className="dropdown-menu" aria-labelledby="account-info-nav-btn">
                                            {this.context.companies.map((item, index) =>
                                                <div key={index} id={item.registerCode} className="dropdown-menu-item" onClick={() => this.handleChangeCompany(item.id)}>
                                                    <div className="dropdown-menu-item-content">
                                                        {index === 0 && <p className="mob-h"><b>{this.context.userName}</b><br /></p>}
                                                        <p>
                                                            {item.companyShortName && <span className="mob-h">{item.companyShortName}<br /></span>}
                                                            {i18next.t("21")} {item.registerCode}
                                                        </p>
                                                    </div>
                                                </div>)}
                                            <ul className="action-list">
                                                {menuItem ?
                                                    <li><NavLink tag={Link} to={menuItem.url}>{menuItem.text}</NavLink></li>
                                                    : ""}
                                                <li className="last"><a onClick={logout}>{i18next.t("exit")}</a></li>
                                            </ul>
                                        </div>
                                    </>
                                }
                            </div>
                            <div className="logout-btn" onClick={logout}>
                                <a>
                                    <span>{i18next.t("exit")}</span>
                                    <Svg src={resources.logoutIcone} />
                                </a>
                            </div>
                        </div>
                    </div>
                    <div className="Burger" style={{
                        height: '34px',
                        width: '34px',
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                        cursor: 'pointer',
                        padding: '0',
                    }}
                        onClick={() => this.setState({ menuOpen: !this.state.menuOpen })}>
                        <svg className={(this.props.menuOpen === true ? 'open' : ' ')} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M12 8C13.1 8 14 7.1 14 6C14 4.9 13.1 4 12 4C10.9 4 10 4.9 10 6C10 7.1 10.9 8 12 8ZM12 10C10.9 10 10 10.9 10 12C10 13.1 10.9 14 12 14C13.1 14 14 13.1 14 12C14 10.9 13.1 10 12 10ZM12 16C10.9 16 10 16.9 10 18C10 19.1 10.9 20 12 20C13.1 20 14 19.1 14 18C14 16.9 13.1 16 12 16Z" fill="black" />
                        </svg>
                        <svg className={(this.props.menuOpen === true ? ' ' : 'open')} width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M14 1.41L12.59 0L7 5.59L1.41 0L0 1.41L5.59 7L0 12.59L1.41 14L7 8.41L12.59 14L14 12.59L8.41 7L14 1.41Z" fill="black" />
                        </svg>
                    </div>
                </Navbar>
            </header>
            <div className={this.state.menuOpen ? "overMob open" : "overMob"} onClick={this.hideOverMob.bind(this)}></div>
        </>;
    }
    handleChangeCompany(companyId) {
        const context = this.context;
        const selectedCompanyIndex = context.companies.findIndex((item) => item.id === companyId);
        const selectedCompany = context.companies[selectedCompanyIndex];
        context.companies.splice(selectedCompanyIndex, 1);
        context.companies.unshift(selectedCompany);
        localStorage.setItem("user", JSON.stringify(context));
        window.location.replace("/");
    }
}