import React from 'react';
import { formModes, attachmentStatuses, companyTypes } from '../../constants';
import { AttachmentsForm } from './AttachmentsForm';
import { statuses } from './Document';
import i18next from "../../i18n";
export function getCurAttacmentsTypes() {
	const { attachmentsTypes, prop_sx_caType, prop_sx_caKind } = this.state;
	return attachmentsTypes?.filter((item) => item.configuration?.some((conf) => conf.counterpartyType === prop_sx_caType && conf.counterpartyKind === prop_sx_caKind && (conf.portalEnabled)));
}
export function getAttachmentConfiguration(attachment,properties) {
	let {prop_sx_caType,prop_sx_caKind,attachmentsTypes}=properties;
	let {documentType}=attachment;
	let configuration=attachmentsTypes.find((item) => item.value===documentType)?.configuration?.find((item) =>
		(item.counterpartyType===prop_sx_caType&&item.counterpartyKind===prop_sx_caKind)
	);
	return configuration;
}
export function renderdocs() {
	const { attachments, formMode, prop_sx_dfState } = this.state;
	const canEditAttachments = formModes.read === formMode && (prop_sx_dfState === statuses.preparing || prop_sx_dfState === statuses.revision);
	let getAttachmentIndicator = (attachment) => {
		let { state, nodeRef } = attachment;
		let configuration = this.getAttachmentConfiguration(attachment, this.state);
		if (configuration) {
			if (!nodeRef && state === attachmentStatuses.NotAdded && configuration?.portalMandatoryAdding)
				return <img src="/img/icn-alert.svg" title={i18next.t("mandatoryDocument")} alt="" />;
			else if ((state === attachmentStatuses.New || state === attachmentStatuses.NotAdded) && configuration?.portalMandatorySigning)
				return <img src="/img/icn-need-sign.svg" title={i18next.t("signatureRequired")} alt="" />;
		}
	}
	return (<div className="large-form doc-form">
		<AttachmentsForm typesOfDocumens={this.getCurAttacmentsTypes.bind(this)()} getAttachmentIndicatorHandler={getAttachmentIndicator.bind(this)} attachments={attachments} edite={canEditAttachments} />
	</div>);
}
export function buildFormItem(item) {
	const { prop_sx_caType, prop_sx_caVAT } = this.state;
	if (["prop_sx_caDocRegNum", "prop_sx_caDocRegDate"].indexOf(item.Name) !== -1 && companyTypes.Individual !== prop_sx_caType) this.state[item.Name] = null;
	else if (prop_sx_caVAT !== true && ["prop_sx_caVATCertificate"].indexOf(item.Name) !== -1);
	else return item;
}
export function checkAttachmentsMandatoryAdding() {
	const {attachments}=this.state;
	for(let i=0;i<attachments.length;++i) {
		let configuration=this.getAttachmentConfiguration(attachments[i],this.state);
		let state=attachments[i].state;
		if(state===attachmentStatuses.NotAdded&&configuration?.portalMandatoryAdding===true)
			return false;
	}
	return true;
}
export function checkAttachmentsMandatorySigning() {
	const {attachments}=this.state;
	for(let i=0;i<attachments.length;++i) {
		let configuration=this.getAttachmentConfiguration(attachments[i],this.state);
		let state=attachments[i].state;
		if ((state === attachmentStatuses.NotAdded || state === attachmentStatuses.New) && configuration?.portalMandatorySigning) return false;
	}
	return true;
}