import React from 'react';
import { actionTypes } from '../../constants';
import { hideModalDialog } from '../ModalDialog';
import { loadData as loadDataP } from './ListOfDocuments';
import { Table } from '../table/Table';
import { TableFilterBlock } from '../table/TableFilterBlock';
import { getArrayIds } from '../helpers/HelperFunctions';
export { getAllowedEnumValues, getFilterValuesFromMenu, getSelectedMenuItem, inboxNavigate, loadDocuments, getSettingsValue } from './ListOfDocuments';
export async function actionHandlerCallback(type = '', ids = []) {
    let { content } = this.state;
    let result = this?.props?.actionHandlerCallback(type, content.rows.filter(row => ids.indexOf(row.columns['Id']) !== -1));
    hideModalDialog('modalDialog');
    await this.actionHandlerCallbackDefault(type, ids);
}
export function renderContent() {
    const { columns, showPagination, showFilter, actionsForAll } = this.props;
    const { content, page, pageSize, pageCount, sortBy, isDesk, filterValues } = this.state;
    const actions = actionsForAll ? actionsForAll : [{ title: "Додати", type: actionTypes.add, isMultiaction: false, btnClass: "btn-primary", sortIndex: 0 }];
    if (content?.columns) {
        content.allColumns.find((item) => item.name === "Status").canFilter = false;
        if (columns?.length > 0) content.columns = content.columns.filter((item) => columns.find((column) => item.name === column));
        content.columns.map(item => item.showFilterInColumn = false);
    }
    return (content && <div>
        {(showFilter !== undefined ? showFilter : true) ? <TableFilterBlock hideSettings={false} filterValues={filterValues} onColumnsUpdateHandler={this.onColumnsUpdateHandler} exportFileName="documents" onSearch={this.onSearch} onSearchClear={this.onSearchClear} columnsSettings={{ columns: (content && content.allColumns) ? content.allColumns : [], selected: (content && content.columns) ? getArrayIds(content.columns) : [] }} items={content ? content.rows : []} getItems={this.getItemsForExport} /> : ""}
        <Table onColumnFilter={this.onColumnFilter} allCount={content.allCount} allowDoubleClickAction={true} handleSortChange={this.handleSortChange} actionHandlerCallback={this.actionHandlerCallback} sortBy={sortBy} isDesk={isDesk} columns={content.columns} items={content.rows} page={page} pageSize={pageSize} pageCount={pageCount} handlePageChange={this.handlePageChange} showPagination={showPagination !== undefined ? showPagination : true} handleChangePageSize={this.handleChangePageSize} actionsForAll={actions} />
    </div>);
}
export async function loadData(page = null, pageSize = null, sortBy = '', isDesk = false, filterValues = []) {
    const { additionalFilter } = this.props;
    const { content } = this.state;
    if (!(content?.rows?.length > 0 && additionalFilter?.Id && content.rows[0].columns.Id === additionalFilter.Id[0].split('/').pop())) await loadDataP.bind(this)(page, pageSize, sortBy, isDesk, filterValues);
}