import React, { Component } from 'react'
import { alfrescoTypes, attachmentStatuses, formFieldTypes, documentStatuses, formModes, History } from '../../constants'
import { buildFormSection, getFormSetting, renderFormHeader, handleGetDocumentError, handlerCloseDocument, getComents, documentActionHandlerCallback, handleSubmit, renderAttachment } from './DocumentHelpers'
import * as HelperFunctions from '../helpers/HelperFunctions';
import { DocumentTitle } from './DocumentTitle'
import { Tabs } from '../Tabs'
import i18n from '../../i18n'
import { CommentsForm } from '../comments/CommentsForm'
import { Table } from '../table/Table'
import { clientSettings } from '../../settings/clientSettings'
import { ExternalSigners } from '../forms/ExternalSigners';
export const statuses = {
    accredited: "accredited",
    rejected: "rejected",
    revision: "revision",
    manager_revision: "manager_revision",
    manager_approval: "manager_approval",
    security_approval: "security_approval",
    preparing: "preparing",
    sign_counterparty: "sign_counterparty",
    signed: "signed"
}
export const documentTabs = {
    information: "information",
    docs: "docs",
    signers: "signers",
    comments: "comments",
    financialinfo: "financialinfo",
    contactinfo: "contactinfo",
}
export const documentSettings = {
    "tabs": [
        {
            "name": documentTabs.information,
            "label": i18n.t("Загальна інформація")
        },
        {
            "name": documentTabs.contactinfo,
            "label": i18n.t("Контактна інформація")
        },
        {
            "name": documentTabs.signers,
            "label": i18n.t("Підписанти"),
            "tabs": [
                {
                    "name": "signers",
                    "label": i18n.t("Акредитовані підписанти")
                },
                {
                    "name": "externalsigners",
                    "label": i18n.t("Додаткові підписанти")
                }
            ]
        },
        {
            "name": documentTabs.financialinfo,
            "label": i18n.t("Фінансова інформація")
        },
        {
            "name": documentTabs.docs,
            "label": i18n.t("Документи")
        },
        {
            "name": documentTabs.comments,
            "label": i18n.t("Коментарі")
        }
    ]
}
function DocumentFormContent(props) {
    return (<div className="form-block large-form doc-form x">
        <div className="form-content">
            <div className="row">
                {props.children}
            </div>
            <div className="required-label"><label className="required" />{i18n.t("requiredFields")}</div>
        </div>
    </div>);
}
export class Document extends Component {
    constructor(props) {
        super(props);
        this.state = this.getInitState();
        this.actionHandlerCallback = documentActionHandlerCallback.bind(this);
        this.buildFormSection = buildFormSection.bind(this);
    }
    getInitState() {
        return {
            formMode: window.location.pathname.split("/")[2].length !== 36 ? formModes.create : formModes.read,
            isCretor: false,
            isPerformer: true,
            activeTab: HelperFunctions.getUrlParam("tab") ?? "0",
            parent: HelperFunctions.getUrlParam('parent'),
            curDocId: window.location.pathname.split("/")[2]
        };
    }
    rendersigners() {
        const { formMode, externalSigners, settings, type, cpAuthorityPersons } = this.state;
        return (type === alfrescoTypes.counterparties ?
            <Tabs isSub={true} tabs={settings.tabs.find((tab) => tab.name === documentTabs.signers)?.tabs}>
                <div className="large-form doc-form">
                    <Table actionHandlerCallback={(type, ids) => require('./DynamicForm').dynamicFormHandler('cpSignatory', type, ids)} actionsForAll={clientSettings.table.Signer.actions.filter(item => formMode == formModes.read ? false : true)} columns={clientSettings.table.Signer.Columns} items={cpAuthorityPersons.map(item => ({ columns: item }))} />
                </div>
                <ExternalSigners signers={externalSigners} />
            </Tabs>
            :
            <ExternalSigners signers={externalSigners} />);
    }
    componentDidMount() {
        getForm.bind(this)();
    }
    componentDidUpdate() {
        const { curDocId } = this.state;
        const newDocId = window.location.pathname.split("/")[2];
        if (curDocId !== newDocId) {
            const keys = Object.keys(this.state)
            const stateReset = keys.reduce((acc, v) => ({ ...acc, [v]: undefined }), {});
            this.setState({ ...stateReset, ...this.getInitState() }, () => getForm.bind(this)());
        }
    }
    render() {
        const { activeTab, settings, type, form, formMode } = this.state;
        if (!settings) return ("");
        try {
            let moduleName = type.split(':')[1];
            for (const [key, value] of Object.entries(require(`./${moduleName}`))) {
                if (typeof value === 'function')
                    this[key] = value.bind(this);
            }
        } catch (e) {
            console.log(e);
        }
        const activeTabs = settings.tabs.filter(item => form.Tabs.find(tab => tab?.toLowerCase() === item.name?.toLowerCase() && !(formMode === formModes.create && [documentTabs.comments, documentTabs.signers].find(item => item === tab?.toLowerCase()))));
        return (<div className="document">
            {(settings.tabs?.length > 0 ?
                <div className="content">
                    <DocumentTitle {...this.state} />
                    <form name="form" onSubmit={(e) => { e.preventDefault(); handleSubmit.bind(this); }}>
                        <Tabs name="tab" tabs={activeTabs} activeTab={activeTab.toString()} tabChangedCallback={(index) => this.setState({ activeTab: index })}>
                            {activeTabs.map((item) => <div className="doc-form-block">{renderFormHeader.bind(this)()}{this["render" + item.name]()}</div>)}
                        </Tabs>
                    </form>
                </div> : "")}
        </div>);
    }
    setLocalStorage() {
        const { prop_sys_node_uuid, prop_sx_state } = this.state;
        fetch(`Document/GetCashe?key=${prop_sys_node_uuid}`, { method: "get" })
            .then(async (response) => {
                if (response.ok) {
                    let text = await response.text();
                    let storage = text ? JSON.parse(text) : localStorage.getItem(prop_sys_node_uuid);
                    if (prop_sx_state === documentStatuses?.project && storage && Object.keys(storage).length > 0) {
                        let updatedState = {};
                        Object.keys(storage).map((key) => {
                            updatedState[key] = storage[key];
                        });
                        this.setState(updatedState);
                    }
                }
            });
    }
    updateServerStorage() {
        const { prop_sys_node_uuid, form } = this.state;
        let storage = JSON.stringify(Object.assign({}, ...form.Fields.filter(item => !item.Name).map((item) => ({ [item.Name]: this.state[item.Name] }))));
        fetch(`Document/SetCashe`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ key: prop_sys_node_uuid, value: storage })
        }).then(() => localStorage.setItem(prop_sys_node_uuid, storage))
    }
    renderinformation = () => (<DocumentFormContent>
        {this.buildFormSection(documentTabs.information)}
        {this.state.type !== alfrescoTypes.counterparties ? renderAttachment.bind(this)() : ''}
    </DocumentFormContent>)
    rendercontactinfo = () => (<DocumentFormContent>{['legalAddress', 'address', 'contacts'].map((item) => this.buildFormSection(item))}</DocumentFormContent>)
    renderfinancialinfo() {
        const { banks, formMode } = this.state;
        return (<div className="large-form doc-form">
            <Table actionHandlerCallback={(type, ids) => require('./DynamicForm').dynamicFormHandler('Bank', type, ids)} actionsForAll={clientSettings.table.Bank.actions.filter(item => formMode == formModes.read ? false : true)} columns={clientSettings.table.Bank.Columns} items={banks.map(item => ({ columns: item }))} />
        </div>);
    }
    rendercomments = () => (<CommentsForm getComents={getComents.bind(this)} documentId={this.state.prop_sys_node_uuid} comments={this.state.comments} handleChangeComents={(comments) => this.setState({ comments: comments })} />)
}
export async function getForm(id, prefix) {
    const _this = this;
    const { formMode } = this.state;
    let Id = id?.split("/")?.pop() ?? window.location.pathname.split("/")[2];
    let initForm = async function (documentId) {
        let data = (formMode === formModes.read || id) ? await fetch(`/document/get/${documentId}`, { method: 'GET', headers: { 'Content-Type': 'application/json' } })
            .then(async response => {
                if (!response.ok) return handleGetDocumentError.bind(this)(response.status);
                let data = await response.json();
                Object.entries(JSON.parse(data.properties)).map(([key, value]) => {
                    _this.state["prop_" + (prefix ?? '') + key.replaceAll(":", "_").replace("-", "_")] = value
                });
                if (prefix) return;
                Object.keys(data).forEach(function (key) {
                    _this.setState({ [key + (prefix ?? '')]: data[key] });
                });
                return data;
            }) : {}
        if (!data || prefix) return;
        const type = this.state.type ?? (data?.type ?? Id);
        const form = await getFormSetting(type);
        if (!form) return;
        let storageTimeInterval = JSON.parse(localStorage.user).storageTimeInterval;
        if (storageTimeInterval && formMode === formModes.edit && this.updateServerStorage) setInterval(this.updateServerStorage.bind(this), storageTimeInterval * 60000);
        if (formMode === formModes.create)
            History.block((prompt) => handlerCloseDocument.bind(this)(prompt.pathname));
        for (let i = 0; i < form?.Fields?.length; ++i) {
            let { Name, Value, Type } = form.Fields[i];
            if (Name) {
                Value = this.state[Name] ? this.state[Name] : Value;
                if (Type === formFieldTypes.datetime && !Value) this.state[Name] = null;
                else if (Type === formFieldTypes.select) {
                    this.state[Name] = form?.Fields[i]?.Values?.Data?.length > 0 ? (Value ? Value : form?.Fields[i]?.Values?.Data[0]?.Value) : "";
                    this.state[`${Name}s`] = form?.Fields[i]?.Values?.Data;
                }
                else this.state[Name] = (Value || Type === formFieldTypes.bool) ? Value : "";
            }
        }
        data.attachmentsTypes = data?.attachmentsTypes ? JSON.parse(data?.attachmentsTypes.replaceAll('sxd:', '')) : '';
        return Object.assign({ type: type, form: form }, data);
    }
    const data = await initForm.bind(this)(Id);
    this.setState({ formMode: formMode });
    if (!data || prefix) return;
    this.setState({ form: data.form })
    const type = data.type;
    this.state.attachments = [];
    if (formMode === formModes.read || id) {
        for (let i = 0; i < data?.attachments?.length; ++i) {
            let { nodeRef, properties, signatures, creatorInfo } = data.attachments[i];
            _this.state.attachments.push({
                nodeRef: nodeRef,
                signs: signatures?.signatures,
                creatorFullName: creatorInfo?.creatorFullName
            })
            Object.keys(properties).forEach(function (key) {
                _this.state.attachments[i][key] = properties[key]
            })
        }
        delete data['attachments']
        this.setState({ ...JSON.parse(JSON.stringify(data).replaceAll(`"id":`, `"Id":`)) })
    }
    try {
        let settingObject = Object.assign(documentSettings, { statuses: statuses, actions: JSON.parse(JSON.stringify(data.form.Actions)).map(item => Object.assign({ modes: [formModes.read] }, item)).map(item => HelperFunctions.objToLowercase(item)) });
        this.setState({ type: type, settings: settingObject });
        if (formMode === formModes.edit) this.setLocalStorage.bind(this)();
        if (id) {
            this.setState({ comments: [], prop_sys_node_uuid: "", formMode: formModes.create, prop_sx_state: documentStatuses?.preparation });
            History.push(type);
        }
    } catch { }
    if (type.toLowerCase() === alfrescoTypes.counterparties.toLowerCase()) {
        const curAttacmentsTypes = this.getCurAttacmentsTypes.bind(this)();
        for (let i = 0; i < curAttacmentsTypes?.length; ++i)
            if (this.state.attachments.findIndex(a => a.documentType === curAttacmentsTypes[i].value) < 0)
                this.state.attachments.push({
                    Id: i,
                    state: attachmentStatuses.NotAdded,
                    documentType: curAttacmentsTypes[i].value,
                    prop_sx_addDocumentType_label: curAttacmentsTypes[i].title
                });
    }
    const { associations } = this.state;
    const signatoryInvoice = associations?.signatoryInvoice;
    if (signatoryInvoice) signatoryInvoice.forEach(async (element, index) => await getForm.bind(_this)(element, `sx_signatoryInvoice${index}`));
    console.log('state', this.state);
}